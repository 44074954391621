import React from 'react'
import Header from '../components/Header'
import Plans3 from "../components/Plans3"
// import Plans2 from '../components/Plans2'
// import Heading from "../components/Heading"
import Footer from '../components/Footer'

export default function Services() {
  return (
    <div>
        <Header></Header>
        {/* <Heading></Heading> */} 
        {/* <Plans2 ></Plans2> */}
        <Plans3></Plans3>
        <Footer></Footer>
        

    </div>
  )
}
